
import { Component, Vue, Watch } from "vue-property-decorator";
import * as StatServices from '@/services/StatServices'
import { ROOT_ACTIONS } from "@/store/actions";

@Component
export default class ReportsIndex extends Vue {

  chartOptions = {
    chart: {
      type: 'line',
      width: 100,
      height: 35,
      sparkline: {
        enabled: true
      }
    },
    tooltip: {
      fixed: {
        enabled: false
      },
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function (seriesName: any) {
            return ''
          }
        }
      },
      marker: {
        show: false
      }
    }
  }

  series = [{
    data: [2, 8, 5, 3, 5]
  }]

  headers = [] as string[]
  headerTexts = {} as Record<string, string>

  data = {} as Record<string, Record<string, number>>
  sparklineData = {} as Record<string, Record<string, number[]>>

  interactionsFilter = {
    from: new Date(2022, 12, 12),
    to: new Date(2023, 1, 1)
  }

  dateRangeSelector = {
    fromToggle: false,
    from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).toISOString().substring(0, 10),
    toToggle: false,
    to: new Date().toISOString().substring(0, 10),
  }

  loading = true;

  @Watch('dateRangeSelector.from', { immediate: true })
  @Watch('dateRangeSelector.to')
  onDateSelect() {
    this.interactionsFilter.from = new Date(this.dateRangeSelector.from);
    this.interactionsFilter.to = new Date(this.dateRangeSelector.to);
  }

  @Watch('interactionsFilter', { deep: true, immediate: true })
  async fetchInteractions() {
    console.log(this.interactionsFilter)
    this.data = {}
    this.headers = []
    this.headerTexts = {}

    this.loading = true

    const interactions = await StatServices.ListInteractions(
      this.interactionsFilter.from,
      this.interactionsFilter.to
    );

    for (let date in interactions) {
      if (!Object.keys(this.data).includes(date)) Object.assign(this.data, { [date]: {} })
      if (!Object.keys(this.sparklineData).includes(date)) Object.assign(this.sparklineData, { [date]: [] })

      for (let interaction in interactions[date]) {
        const key = `interaction_${interactions[date][interaction].type}`
        if (Object.keys(this.data[date]).includes(key)) {
          this.data[date][`${key}`]++
        } else {
          Object.assign(this.data[date], { [key]: 1 })
        }
      }
    }

    console.log(this.data)

    Object.keys(interactions).forEach(d => {
      Object.assign(this.headerTexts, {
        [d]: new Date(d).toLocaleDateString('nl-BE')
      })
    })

    this.headers = Object.keys(interactions).map(d => new Date(d)).sort((a, b) => a.getTime() - b.getTime()).map(d => d.toISOString().substring(0, 10))

    this.loading = false;
  }


  copyTableToClipboard() {
    const table = document.querySelector('table');
    const tableData = this.getFormattedTable(table);

    // Create a new textarea element to hold the table data
    const textarea = document.createElement('textarea');
    textarea.value = tableData;
    document.body.appendChild(textarea);

    // Select and copy the content from the textarea
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea element
    document.body.removeChild(textarea);

    this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
      color: 'info',
      text: 'Tabel naar klembord gekopieerd'
    })
  }

  getFormattedTable(table: any) {
    // Function to format the table as a CSV-like format
    const rows = Array.from(table.querySelectorAll('tr'));
    return rows
      .map((row: any) => {
        const cells = Array.from(row.querySelectorAll('td, th')) as any[];
        return cells.map((cell) => cell.innerText).join('\t');
      })
      .join('\n');
  }
}
